import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import Privacy from "./components/Privacy/Privacy";
import FAQPage from "./components/FAQs/FAQsPage";
import StickySocialIcons from "./components/Stick/Stick";
import {
  HashRouter,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, updateLoad] = useState(true);

  useEffect(() => {
    // Function to post data to the API
    const postVisitorData = async () => {
      try {
        await fetch('https://admin.doorpal.app/api/save-visitor', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error posting visitor data:', error);
      }
    };

    // Call the API when the component mounts
    postVisitorData();

    // Handle URL processing and preload state
    if (window.location.href) {
      let newUrl = window.location.href.split("?");
      if (newUrl[1]) {
        localStorage.setItem("door_id_for_website", newUrl[1]);
      }
    }

    const timer = setTimeout(() => {
      updateLoad(false);
    }, 1200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <HashRouter>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tutorials" element={<Projects />} />
          <Route path="/help" element={<About />} />
          <Route path="/terms" element={<Resume />} />
          <Route path="/about" element={<FAQPage />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </div>
      <StickySocialIcons />
    </HashRouter>
  );
}

export default App;
